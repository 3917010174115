import { Button, Divider, Link, Skeleton, Tooltip } from '@chakra-ui/react';
import { fetchApi, useApi } from '../../utils/useApi';
import { useEffect, useState } from 'react';

import ContentHeader from '../../components/ContentHeader';
import DeleteModal from '../../components/DeleteModal';
import MaiaFullGridWidthContent from '../../components/layout/MaiaFullGridWidthContent';
import MaiaMainGrid from '../../components/layout/MaiaMainGrid';
import SharepointSiteSelectionModal from './SharepointSiteSelectionModal';
import SharepointSiteStatusTable from './SharepointSiteStatusTable';
import { captureException } from '@sentry/react';
import { faPlug } from '@fortawesome/pro-regular-svg-icons';
import { getMsAuthFlowLink } from '../../utils/msAuthFlow';
import { useBoundStore } from '../../store/useBoundStore';
import { useToastManagerHook } from '../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

function SharepointSettings() {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const workspaceUserType = useBoundStore((state) => state.workspaceUserType);
  const { showToast } = useToastManagerHook();

  const [siteSelectionModalOpen, setSiteSelectionModalOpen] = useState(false);
  const [siteDeselectWarningModalOpen, setSiteDeselectWarningModalOpen] =
    useState<string | undefined>(undefined);
  const [deactivationWarningModalOpen, setDeactivationWarningModalOpen] =
    useState(false);

  const {
    data: sharepointIntegrationStatus,
    error: sharepointStatusError,
    refetch: refetchIntegrationStatus,
  } = useApi(supabase, '/integrations', '/sharepoint/status', {
    method: 'POST',
    workspaceId,
  });

  useEffect(() => {
    refetchIntegrationStatus();
  }, []);

  const loading = !sharepointIntegrationStatus;

  useEffect(() => {
    const interval = setInterval(refetchIntegrationStatus, 20000); // refresh status every 20 secs

    return () => {
      clearInterval(interval);

      // Request permission sync when the user leaves this page (the component is unmounted).
      // Use case: An admin has added a new sharepoint site and its files have already been synced to some degree. -> The admin goes to the chat to try it out.
      fetchApi(
        supabase,
        '/integrations',
        '/sharepoint/request_permission_sync',
        {
          method: 'POST',
          workspaceId,
        }
      ).catch((e) => {
        captureException(e);
      });
    };
  }, []);

  useEffect(() => {
    if (sharepointStatusError) {
      showToast({
        status: 'error',
        title: t('integrationSettings.sharepoint.sharepointStatusError'),
      });
    }
  }, [sharepointStatusError]);

  async function handleSiteSelection(selectedSiteIds: string[]) {
    const res = await fetchApi(
      supabase,
      '/integrations',
      '/sharepoint/select_sites',
      { method: 'POST', workspaceId, sharepointSiteIds: selectedSiteIds }
    );

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    refetchIntegrationStatus();
  }

  async function handleSiteDeselection(deselectedSiteIds: string[]) {
    const res = await fetchApi(
      supabase,
      '/integrations',
      '/sharepoint/deselect_sites',
      { method: 'POST', workspaceId, sharepointSiteIds: deselectedSiteIds }
    );

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    refetchIntegrationStatus();
  }

  async function handleMSLogout() {
    const res = await fetchApi(
      supabase,
      '/integrations',
      '/sharepoint/logout',
      { method: 'POST', workspaceId }
    );

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    refetchIntegrationStatus();
  }

  async function handleDeactivateIntegration() {
    const res = await fetchApi(
      supabase,
      '/integrations',
      '/sharepoint/deactivate',
      { method: 'POST', workspaceId }
    );

    if (!res.success) {
      showToast({ title: t('general.tryAgainError'), status: 'error' });
      return;
    }

    setDeactivationWarningModalOpen(false);
    refetchIntegrationStatus();
  }

  const fullyEnabled =
    sharepointIntegrationStatus?.activatedForWorkspace &&
    sharepointIntegrationStatus.adminPermissionsGranted;

  return (
    <MaiaMainGrid>
      <MaiaFullGridWidthContent className="flex flex-col gap-4">
        <ContentHeader
          headingLabel={t('integrationSettings.title')}
          icon={faPlug}
          iconLabel={t('integrationSettings.title')}
          buttons={
            ['ADMIN', 'OWNER'].includes(workspaceUserType)
              ? sharepointIntegrationStatus?.activatedForWorkspace
                ? [
                    <Tooltip
                      isDisabled={
                        fullyEnabled && !!sharepointIntegrationStatus.loggedInAs
                      }
                      label={
                        !fullyEnabled
                          ? t(
                              'integrationSettings.sharepoint.connectSitesNoAdminPermissionTooltip'
                            )
                          : t(
                              'integrationSettings.sharepoint.connectSitesNotLoggedInTooltip'
                            )
                      }
                    >
                      <Button
                        isDisabled={
                          !fullyEnabled ||
                          !sharepointIntegrationStatus.loggedInAs
                        }
                        onClick={() => {
                          setSiteSelectionModalOpen(true);
                        }}
                      >
                        {t('integrationSettings.sharepoint.connectSitesButton')}
                      </Button>
                    </Tooltip>,
                    <Button
                      className="self-start"
                      colorScheme="red"
                      onClick={() => {
                        setDeactivationWarningModalOpen(true);
                      }}
                    >
                      {t('integrationSettings.sharepoint.deactivateButton')}
                    </Button>,
                  ]
                : [
                    <Skeleton isLoaded={!loading}>
                      <Button
                        as={Link}
                        className="self-start"
                        colorScheme="maia-accent"
                        href={getMsAuthFlowLink({
                          redirectTo: window.location.pathname,
                          workspaceId,
                          action: 'activate',
                        }).toString()}
                      >
                        {t('integrationSettings.sharepoint.activateButton')}
                      </Button>
                    </Skeleton>,
                  ]
              : []
          }
        />
        <Skeleton isLoaded={!loading}>
          <div className="flex flex-col gap-6">
            {/* ---------------------------------------------------------------- Status text ---------------------------------------------------------------- */}
            <div className="whitespace-pre-line font-medium">
              {fullyEnabled
                ? t('integrationSettings.sharepoint.activated')
                : sharepointIntegrationStatus?.activatedForWorkspace
                  ? t('integrationSettings.sharepoint.notAuthenticated')
                  : workspaceUserType === 'USER'
                    ? t('integrationSettings.sharepoint.notActivatedUser')
                    : t('integrationSettings.sharepoint.notActivated')}
              {fullyEnabled &&
              (workspaceUserType === 'ADMIN' ||
                workspaceUserType === 'OWNER') &&
              !sharepointIntegrationStatus.loggedInAs ? (
                <>
                  <br />
                  <br />
                  {t(
                    'integrationSettings.sharepoint.activatedButNotLoggedInAdmin'
                  )}
                </>
              ) : null}
            </div>

            {/* ---------------------------------------------------------------- Connected Sites ---------------------------------------------------------------- */}
            {fullyEnabled &&
            sharepointIntegrationStatus.loggedInAs &&
            !sharepointIntegrationStatus.reauthRequired ? (
              <>
                <Divider />
                <div className="font-semibold">
                  {t('integrationSettings.sharepoint.connectedSites')}
                </div>
                <div className="font-medium">
                  {t('integrationSettings.sharepoint.connectedSitesInfo')}
                </div>
                <SharepointSiteStatusTable
                  sites={sharepointIntegrationStatus.selectedSites ?? []}
                  onDisconnectClick={(sharepointSiteId) => {
                    setSiteDeselectWarningModalOpen(sharepointSiteId);
                  }}
                />
              </>
            ) : fullyEnabled &&
              sharepointIntegrationStatus.loggedInAs &&
              sharepointIntegrationStatus.reauthRequired ? (
              /* ------------------------------------------------ reauth required ------------------------------------------------ */
              <>
                <div className="whitespace-pre-line font-medium">
                  {t(
                    'integrationSettings.sharepoint.personalConnection.infoAccountNeedsRefresh'
                  )}
                </div>
                <Button
                  as={Link}
                  className="self-start"
                  variant={'outline'}
                  href={getMsAuthFlowLink({
                    redirectTo: window.location.pathname,
                    workspaceId,
                    action: 'userAuth',
                  }).toString()}
                >
                  {t(
                    'integrationSettings.sharepoint.personalConnection.refreshConnectionButton'
                  )}
                </Button>
              </>
            ) : null}
            {/* ---------------------------------------------------------------- Connected Account ---------------------------------------------------------------- */}
            {(workspaceUserType === 'USER' && fullyEnabled) ||
            ((workspaceUserType === 'OWNER' || workspaceUserType === 'ADMIN') &&
              sharepointIntegrationStatus?.activatedForWorkspace) ? (
              <>
                <Divider />
                <div className="font-semibold">
                  {t('integrationSettings.sharepoint.personalConnection.title')}
                </div>
                {/* ------------------------------------------------ logged in ------------------------------------------------ */}
                {sharepointIntegrationStatus.loggedInAs ? (
                  <>
                    <div className="whitespace-pre-line font-medium">
                      {t(
                        'integrationSettings.sharepoint.personalConnection.infoAccountConnected'
                      )}
                    </div>
                    <div className="font-medium">{`${t('integrationSettings.sharepoint.personalConnection.loggedInAsUser')}: ${sharepointIntegrationStatus.loggedInAs.name} (${sharepointIntegrationStatus.loggedInAs.mail})`}</div>
                    <div className="font-medium">{`${t('integrationSettings.sharepoint.personalConnection.loggedInAsOrg')}: ${sharepointIntegrationStatus.loggedInAs.tenantName}`}</div>
                    <Button
                      className="self-start"
                      variant={'outline'}
                      onClick={handleMSLogout}
                    >
                      {t(
                        'integrationSettings.sharepoint.personalConnection.disconnectAccountButton'
                      )}
                    </Button>
                  </>
                ) : (
                  /* ------------------------------------------------ not logged in ------------------------------------------------ */
                  <>
                    <div className="whitespace-pre-line font-medium">
                      {t(
                        'integrationSettings.sharepoint.personalConnection.infoAccountNotConnected'
                      )}
                    </div>
                    <Button
                      as={Link}
                      className="self-start"
                      colorScheme="maia-purple"
                      href={getMsAuthFlowLink({
                        redirectTo: window.location.pathname,
                        workspaceId,
                        action: 'userAuth',
                      }).toString()}
                    >
                      {t(
                        'integrationSettings.sharepoint.personalConnection.connectAccountButton'
                      )}
                    </Button>
                  </>
                )}
              </>
            ) : null}
          </div>
        </Skeleton>
      </MaiaFullGridWidthContent>
      {/* ---------------------------------------------------------------- Modals ---------------------------------------------------------------- */}
      {siteSelectionModalOpen ? (
        <SharepointSiteSelectionModal
          isOpen={siteSelectionModalOpen}
          initialSelectedSiteIds={
            sharepointIntegrationStatus?.selectedSites.map((site) => {
              return site.siteId;
            }) ?? []
          }
          onSiteSelect={handleSiteSelection}
          onClose={() => {
            setSiteSelectionModalOpen(false);
          }}
        />
      ) : null}
      {siteDeselectWarningModalOpen ? (
        <DeleteModal
          isOpen={!!siteDeselectWarningModalOpen}
          onClose={() => setSiteDeselectWarningModalOpen(undefined)}
          onConfirm={() => {
            handleSiteDeselection([siteDeselectWarningModalOpen]);
            setSiteDeselectWarningModalOpen(undefined);
          }}
          title={t('integrationSettings.sharepoint.disconnectSiteModal.title')}
          subtitle={t(
            'integrationSettings.sharepoint.disconnectSiteModal.subtitle'
          )}
          deleteButtonLabel={t(
            'integrationSettings.sharepoint.connectedSitesTable.disconnect'
          )}
        />
      ) : null}
      {deactivationWarningModalOpen ? (
        <DeleteModal
          isOpen={deactivationWarningModalOpen}
          onClose={() => setDeactivationWarningModalOpen(false)}
          onConfirm={handleDeactivateIntegration}
          title={t('integrationSettings.sharepoint.deactivateModal.title')}
          subtitle={t(
            'integrationSettings.sharepoint.deactivateModal.subtitle'
          )}
          deleteButtonLabel={t(
            'integrationSettings.sharepoint.deactivateButton'
          )}
        />
      ) : null}
    </MaiaMainGrid>
  );
}

export default SharepointSettings;
