/**
 * This file defines the routes of the MAIA REST API as a type.
 * It provides a structured way to define the HTTP methods, route prefixes, and specific routes
 * along with their request and response structures.
 *
 * Through this structuring it is possible to keep the routes and their request
 * and response types in sync between the api service and the app.
 */
export const SEARCH_ROUTE_PREFIX = '/search';
export const EMBEDDING_ROUTE_PREFIX = '/embeddings';
export const PAYMENT_ROUTE_PREFIX = '/payment';
export const SHARE_ROUTE_PREFIX = '/share';
export const CHAT_ROUTE_PREFIX = '/chat';
export const METRIC_ROUTE_PREFIX = '/metric';
export const USER_ROUTE_PREFIX = '/user';
export const TEST_ROUTE_PREFIX = '/test';
export const FILE_ROUTE_PREFIX = '/files';
export const WORKSPACE_ROUTE_PREFIX = '/workspace';
export const SEARCH_ROUTE_ANSWER = '/answer';
export const SEARCH_ROUTE_SNIPPETS = '/snippets';
export const EMBEDDING_ROUTE_EMBED_PREMIUM = '/embed_premium';
export const EMBEDDING_ROUTE_EMBED_PREMIUM_REMAINING_QUOTA = '/embed_premium_remaining_quota';
export const METRIC_ROUTE_VARIOUS = '/various';
export const METRIC_ROUTE_SET_SEARCH_TEST_DATA = '/search';
export const METRIC_ROUTE_SET_CHAT_TEST_DATA = '/chat';
export const METRIC_ROUTE_SEARCH_IN_TEST_DATA = '/marked';
export const PAYMENT_ROUTE_UPGRADE_PLAN = '/upgrade';
export const PAYMENT_ROUTE_RENEW_WORKSPACE = '/renew_workspace';
// export const PAYMENT_ROUTE_BUY_POWER_MODE = '/buy_powermode';
export const PAYMENT_ROUTE_STRIPE_WEBHOOK = '/stripe_webhook';
export const PAYMENT_ROUTE_LOAD_PORTAL = '/load_portal';
export const PAYMENT_ROUTE_BUY_ADA_PAGES = '/buy_ada_pages';
export const PAYMENT_ROUTE_TRIAL_ELIGIBILITY = '/trial_eligibility';
export const PAYMENT_ROUTE_START_TRIAL = '/start_trial';
export const SHARE_ROUTE_SHARE_BUCKET = '/bucket';
export const SHARE_ROUTE_UNSHARE_BUCKET = '/unshare_bucket';
export const SHARE_ROUTE_RENAME_BUCKET = '/rename_bucket';
export const SHARE_ROUTE_CHANGE_ACCESS = '/change_access';
export const SHARE_ROUTE_PUBLIC_IN_WORKSPACE = '/bucket_public_in_workspace';
export const CHAT_ROUTE_CHAT = '/v1';
export const CHAT_ROUTE_TOPIC = '/get_topic';
export const USER_ROUTE_DELETE = '/delete';
export const USER_ROUTE_NO_WORKSPACE = '/no_workspace';
export const USER_ROUTE_USAGE = '/usage';
export const USER_ROUTE_ACKNOWLEDGE_BANNER = '/acknowledge_banner';
export const TEST_ROUTE_SEARCH = '/search';
export const TEST_ROUTE_CHAT = '/chat';
export const FILE_ROUTE_CREATE_COLLECTION = '/create_collection';
export const FILE_ROUTE_DELETE_COLLECTION = '/delete_collection';
export const FILE_ROUTE_MIGRATE_COLLECTION = '/migrate_collection';
export const FILE_ROUTE_PREVIEW_URL = '/preview_url';
export const WORKSPACE_ROUTE_ADD_USERS = '/add_users';
export const WORKSPACE_ROUTE_ADD_USERS_INTENT = '/add_users_intent';
export const WORKSPACE_ROUTE_REMOVE_USER = '/remove_user';
export const WORKSPACE_ROUTE_UPDATE_USERS = '/update_users';
export const WORKSPACE_ROUTE_UPDATE_USER_ROLE = '/update_users_role';
export const WORKSPACE_ROUTE_TRANSFER_OWNERSHIP = '/transfer_ownership';
export const WORKSPACE_ROUTE_UPDATE_NAME = '/name';
export const WORKSPACE_ROUTE_IS_STRIPE_CUSTOMER = '/is_stripe_customer';
export const WORKSPACE_ROUTE_GENERATE_INVITE_CODE = '/generate_invite_code';
export const WORKSPACE_ROUTE_DISABLE_INVITE_CODE = '/disable_invite_code';
export const WORKSPACE_ROUTE_JOIN_WITH_CODE = '/join_with_code';
export const IFRAME_ROUTE_PREFIX = '/iframe';
export const IFRAME_ROUTE_INFO = '/:iframe_id';
export const IFRAME_ROUTE_AVAILABLE_COLLECTIONS = '/:iframe_id/available_collections';
export const IFRAME_ROUTE_RATE_LIMIT = '/:iframe_id/rate_limit';
export const IFRAME_ROUTE_GET_PRESIGNED_URL = '/presigned_url';
export const AUTH_ROUTE_PREFIX = '/auth';
export const AUTH_ROUTE_LOGIN = '/login';
export const AUTH_ROUTE_TWO_FACTOR_CHALLENGE = '/two_factor_challenge';
export const AUTH_ROUTE_TWO_FACTOR = '/two_factor';
// Keeping Email MFA route naming convention consistent with the supabase MFA
export const AUTH_ROUTE_EMAIL_ENROLL = '/email/enroll';
export const AUTH_ROUTE_EMAIL_ENROLL_VERIFY = '/email/enroll_verify';
export const INTEGRATION_ROUTE_PREFIX = '/integrations';
export const INTEGRATION_ROUTE_SHAREPOINT_STATUS = '/sharepoint/status';
export const INTEGRATION_ROUTE_ACTIVATE_SHAREPOINT = '/sharepoint/activate';
export const INTEGRATION_ROUTE_DEACTIVATE_SHAREPOINT = '/sharepoint/deactivate';
export const INTEGRATION_ROUTE_SHAREPOINT_LOGIN = '/sharepoint/login';
export const INTEGRATION_ROUTE_SHAREPOINT_LOGOUT = '/sharepoint/logout';
export const INTEGRATION_ROUTE_SHAREPOINT_AVAILABLE_SITES = '/sharepoint/available_sites';
export const INTEGRATION_ROUTE_SHAREPOINT_SELECT_SITES = '/sharepoint/select_sites';
export const INTEGRATION_ROUTE_SHAREPOINT_DESELECT_SITES = '/sharepoint/deselect_sites';
export const INTEGRATION_ROUTE_SHAREPOINT_JOIN_WAITLIST = '/sharepoint/join_waitlist';
export const INTEGRATION_ROUTE_SHAREPOINT_PERMISSION_SYNC = '/sharepoint/request_permission_sync';
